import getEnv from "./env"

// Define the enum for all features
export enum Features {
  CONNECTORS = "connectors",
  ASSETS = "assets",
  REST_API_UPLOAD = "restApiUpload",
  UPLOAD_HISTORY = "uploadHistory",
  POLICIES = "policies",
  OUTGOING_DATA_OFFERS = "outgoingDataOffers",
  SUBSCRIBED_DATA_OFFERS = "subscribedDataOffers",
  USE_CASES = "usecases",
  DIGITAL_TWIN_REGISTRY = "digitalTwinRegistry",
  FILE_UPLOAD = "fileUpload",
  PREPARE = "prepare",
  EDIT_MODELS = "edit-models",
  CROSS_RELATIONS = "crossrelations",
  CONVERTERS = "converters",
  API_ACCESS = "api-access",
  LOGS = "logs",
  PUBLICATION_TEMPLATES = "publication-templates",
  DIGITAL_TWIN_BROWSER = "digitalTwinBrowser",
  DOWNLOAD_OFFERS_HISTORY = "downloadOffersHistory"
}

const NON_AUTOMOTIVE = [
  Features.CONNECTORS,
  Features.ASSETS,
  Features.REST_API_UPLOAD,
  Features.UPLOAD_HISTORY,
  Features.POLICIES,
  Features.OUTGOING_DATA_OFFERS,
  Features.SUBSCRIBED_DATA_OFFERS,
  Features.PUBLICATION_TEMPLATES,
  Features.DOWNLOAD_OFFERS_HISTORY
]

const AUTOMOTIVE = [
  Features.USE_CASES,
  Features.DIGITAL_TWIN_REGISTRY,
  Features.CONNECTORS,
  Features.FILE_UPLOAD,
  Features.REST_API_UPLOAD,
  Features.UPLOAD_HISTORY,
  Features.PREPARE,
  Features.EDIT_MODELS,
  Features.CROSS_RELATIONS,
  Features.CONVERTERS,
  Features.API_ACCESS,
  Features.POLICIES,
  Features.OUTGOING_DATA_OFFERS,
  Features.SUBSCRIBED_DATA_OFFERS,
  Features.LOGS,
  Features.PUBLICATION_TEMPLATES,
  Features.DIGITAL_TWIN_BROWSER,
  Features.DOWNLOAD_OFFERS_HISTORY
]

const PRODUCT_FLAVOUR = getEnv("VITE_PRODUCT_FLAVOUR") // Accessing the environment variable

export function featureCheck(features: Features[]) {
  let featureList: Features[] = []

  if (PRODUCT_FLAVOUR === "NON_AUTOMOTIVE") {
    featureList = NON_AUTOMOTIVE
  } else if (PRODUCT_FLAVOUR === "AUTOMOTIVE") {
    featureList = AUTOMOTIVE
  }

  // Check if all the features are included in the selected feature list
  return features.every((feature: Features) => featureList.includes(feature))
}
